import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { AxiosResponse } from 'axios'

import { AnyObject, TableResponse, TopRequest } from '../../types/multy'
import { processObject, transformFilters } from '../../utils/shared'

import Filters from './Filters'
import MultyTable from './MultyTable'
import { trackEvent } from '../../utils/mixpanel'
import api from "../../utils/api.ts";
import { useNavigate } from 'react-router-dom';

export default function MultySection() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tableResponse, setTableResponse] = useState<TableResponse | null>(null)
  const [filters, setFilters] = useState<AnyObject | null>(null)
  const [defaultFilters, setDefaultFilters] = useState<AnyObject | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [timeframe, setTimeframe] = useState<string>('monthly')
  const navigate = useNavigate()

  const areDefaultFilters = useMemo(() => {
    if (!filters) {
      return true
    }

    const filteredObject = Object.entries(processObject(filters)).filter(([, value]) => value?.start || value?.end)
    return isEqual(Object.fromEntries(filteredObject), defaultFilters)
  }, [filters, defaultFilters])

  async function loadTable(filters: TopRequest | null = null) {
    try {
      setIsLoading(true)
      const { data }: AxiosResponse<TableResponse> = await api.post(`${import.meta.env.VITE_API}/top/table/${timeframe}`, filters ?? {})
      setTableResponse(data)
      setFilters(data.filters)
      setDefaultFilters(data.defaultFilters)
      setIsLoading(false)
    } catch (error: unknown) {
      console.error(error);
      navigate('/about');
    }
  }

  // Initial load
  useEffect(() => {
    loadTable()
  }, [timeframe])

  // Turning on or off the socket based on filters (they should match the default ones)[areDefaultFilters])

  // Analytics
  useEffect(() => {
    if (!defaultFilters) return

    if (areDefaultFilters) {
      trackEvent('Top wallets unloading')
    } else {
      trackEvent('Filters', filters ?? {})
    }
  }, [defaultFilters, filters, areDefaultFilters])

  // Submit the filters modal
  async function onFiltersSubmit(data: AnyObject) {
    setIsModalOpen(false)
    await loadTable(transformFilters(data, true))
  }

  async function resetFilters() {
    setIsModalOpen(false)
    await loadTable()
  }

  return (
    <section className="mt-16 md:mt-20 flex flex-col" id="multy">
      <div className="flex flex-col gap-4 justify-between mb-6 md:flex-row md:items-center">
        <h2 className="text-h2 font-bold">{t('topWallets')}</h2>
        <p>
          {tableResponse?.data[0]?.endTimestamp ? dayjs.unix(tableResponse?.data[0]?.endTimestamp / 1000).format('DD.MM.YYYY HH:mm') : ''}
        </p>
        {isLoading ? (
          <div className="flex items-center gap-6">
            <div
              className="bg-skeleton dark:bg-skeleton-dark w-[135px] h-[24px] rounded-md animate-pulse order-1 md:order-none"></div>
            <div className="bg-skeleton dark:bg-skeleton-dark w-[110px] h-[44px] rounded-md animate-pulse"></div>
          </div>
        ) : (
          <div className="flex items-center gap-6">
            <p className="order-1 text-[0.75rem] text-dark-gray dark:text-very-light-blue md:text-[1rem] md:order-none">
              {tableResponse?.dayCount
                ? `${dayjs().subtract(tableResponse.dayCount, 'day').format('DD.MM.YY')} - ${dayjs().format('DD.MM.YY')}`
                : ''}
            </p>
            <button
              className="bg-white dark:bg-blue px-4 py-[0.625rem] rounded-md font-semibold flex items-center gap-3"
              onClick={() => setIsModalOpen(true)}
            >
              <span>{t('filters')}</span>
              <i className="fa-solid fa-filter" style={{ color: !areDefaultFilters ? 'rgb(126, 227, 83)' : '' }}></i>
            </button>
          </div>
        )}
      </div>

      {/* Timeframe switch */}
      <div className="flex gap-4 mb-4">
        {['monthly', 'weekly', 'daily'].map((option) => (
          <button
            key={option}
            className={`px-4 py-2 rounded-md font-semibold ${timeframe === option ? 'bg-line text-white' : 'bg-blue-700 dark:bg-blue-700'}`}
            onClick={() => setTimeframe(option)}
          >
            {t(option)}
          </button>
        ))}
      </div>

      <MultyTable tableData={tableResponse?.data} isLoading={isLoading} timeframe={timeframe} />
      {!isLoading && filters && (
        <Filters
          onSubmit={onFiltersSubmit}
          currentFilters={filters}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          resetFilters={resetFilters}
        />
      )}
    </section>
  )
}
